<template>
    <div :class="['item', { disabled: state === 'add' }, { move: state === 'del' }]">
        <div class="item-top">
            <div class="main-title">{{ mainTarget.title }}</div>
            <div class="main-val">
                <span class="num">{{ mainTarget.value }}</span
                >{{ mainTarget.unit }}
            </div>
            <div class="main-add" v-if="mainTarget.buttonName">
                <mdos-button @click="handleMainTargetClick">{{
                    mainTarget.buttonName
                }}</mdos-button>
            </div>
            <div class="opt" v-if="state !== 'none'">
                <i
                    v-if="state === 'del'"
                    @click="handleOptDelClick"
                    class="del el-icon-remove-outline"
                ></i>
                <i
                    v-if="state === 'add'"
                    @click="handleOptAddClick"
                    class="add el-icon-circle-plus-outline"
                ></i>
            </div>
        </div>
        <div class="item-bottom">
            <div
                class="item-bottom-item"
                :style="{ flex: item.span }"
                v-for="(item, index) in smallTarget"
                :key="index"
            >
                <div class="small-title">{{ item.title }}</div>
                <div class="small-val">
                    <span class="num">{{ item.value }}</span
                    >{{ item.unit }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        mainTarget: {
            type: Object,
            default: () => ({
                title: '本月待完成面积',
                value: '16232',
                unit: '平米',
                buttonName: ''
            })
        },
        state: {
            type: String,
            default: 'none' // none/add/del
        },
        smallTarget: {
            type: Array,
            default: () => [
                {
                    title: '本月已完成面积',
                    value: '7253',
                    unit: '平米',
                    span: 2
                },
                {
                    title: '本月完成率',
                    value: '72.3',
                    unit: '%',
                    span: 1
                }
            ]
        }
    },
    computed: {},
    methods: {
        handleMainTargetClick() {
            if (this.state === 'none') {
                this.$emit('mainTargetClick');
            }
        },
        handleOptDelClick() {
            this.$emit('optDelClick');
        },
        handleOptAddClick() {
            this.$emit('optAddClick');
        }
    },
    mounted() {
        let obj = { a: { b: { c: { d: 0 } } } };

        console.log(obj?.a?.b?.c?.d ?? 'test');
    }
};
</script>

<style lang="less" scoped>
.item {
    width: 390px;
    box-sizing: border-box;
    padding: 20px 30px;
    background: #ffffff;
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    &.move {
        cursor: move;
    }
    &.disabled::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f8f8f8;
        opacity: 0.7;
        z-index: 10;
        border-radius: 8px;
    }
    &.active,
    &:hover {
        box-shadow: 0px 9px 12px 2px rgba(68, 95, 121, 0.1);
    }
    .item-top {
        margin-bottom: 24px;
        position: relative;
        .main-title {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .main-val {
            color: #1089e9;

            .num {
                font-size: 30px;
                margin-right: 5px;
            }
        }
        .main-add {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .opt {
            position: absolute;
            right: -10px;
            top: -10px;
            z-index: 11;
            cursor: pointer;
            .del,
            .add {
                color: #999;
                font-size: 24px;
            }
            .del:hover {
                color: #ff424e;
            }
            .add:hover {
                color: #1089e9;
            }
        }
    }
    .item-bottom {
        display: flex;
        justify-content: space-between;
        .item-bottom-item {
            flex: 2;
            .small-title {
                margin-bottom: 10px;
            }
            .small-val {
                color: #1089e9;
                .num {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
