<template>
    <app-main>
        <template v-slot:main>
            <div class="my-app-list">
                <div class="title">工作卡片设置</div>
                <div class="title-sub">目前显示的卡片</div>
                <vue-sort-layout
                    :expand="{ chosenClass: 'sortable-chosen' }"
                    class="card-box"
                    v-model="cardList"
                >
                    <components
                        v-for="card in cardList"
                        :key="card.id"
                        v-bind="card"
                        :is="card.type"
                    ></components>
                </vue-sort-layout>
                <div class="title-sub">不显示的卡片</div>
                <div class="card-box">
                    <components
                        v-for="card in hideCardList"
                        :key="card.id"
                        v-bind="card"
                        :is="card.type"
                    ></components>
                </div>
                <div class="card-opt">
                    <mdos-button>确定</mdos-button>
                    <mdos-button type="assist">取消</mdos-button>
                </div>
            </div>
        </template>
    </app-main>
</template>
<script>
import AppMain from '@/components/layout/appMain';
import WorkItem from './workItem';

export default {
    data() {
        return {
            cardList: [
                {
                    id: 0,
                    state: 'del',
                    type: 'WorkItem',
                    mainTarget: {
                        title: '本月待完成面积',
                        value: '16232',
                        unit: '平米',
                        buttonName: '新增招商目标'
                    }
                },
                {
                    id: 1,
                    state: 'del',
                    type: 'WorkItem',
                    mainTarget: {
                        title: '跟进中的招商任务',
                        value: '10',
                        unit: '个'
                    },
                    smallTarget: [
                        {
                            title: '本月已完成任务',
                            value: '8',
                            unit: '个'
                        },
                        {
                            title: '本月完成率',
                            value: '69.2',
                            unit: '%'
                        }
                    ]
                },
                {
                    id: 2,
                    state: 'del',
                    type: 'WorkItem'
                }
            ],
            hideCardList: [
                {
                    id: 0,
                    state: 'add',
                    type: 'WorkItem',
                    mainTarget: {
                        title: '本月待完成面积',
                        value: '16232',
                        unit: '平米',
                        buttonName: '新增招商目标'
                    }
                },
                {
                    id: 1,
                    state: 'add',
                    type: 'WorkItem',
                    mainTarget: {
                        title: '跟进中的招商任务',
                        value: '10',
                        unit: '个'
                    },
                    smallTarget: [
                        {
                            title: '本月已完成任务',
                            value: '8',
                            unit: '个'
                        },
                        {
                            title: '本月完成率',
                            value: '69.2',
                            unit: '%'
                        }
                    ]
                },
                {
                    id: 2,
                    state: 'add',
                    type: 'WorkItem'
                },
                {
                    id: 3,
                    state: 'add',
                    type: 'WorkItem'
                }
            ]
        };
    },
    components: {
        AppMain,
        WorkItem
    },
    computed: {},
    methods: {},
    mounted() {}
};
</script>

<style lang="less" scoped>
.my-app-list {
    width: 1248px;
    margin: 32px auto;
    .card-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .sortable-chosen {
        border: 1px dashed #1089e9;
    }
    .title {
        font-size: 16px;
        color: #333;
        margin-bottom: 16px;
        position: relative;
    }
    .title-sub {
        font-size: 16px;
        color: #666;
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 1280px) {
    .my-app-list {
        width: 932px;
    }
}
</style>
