import { render, staticRenderFns } from "./workItem.vue?vue&type=template&id=754eee50&scoped=true&"
import script from "./workItem.vue?vue&type=script&lang=js&"
export * from "./workItem.vue?vue&type=script&lang=js&"
import style0 from "./workItem.vue?vue&type=style&index=0&id=754eee50&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754eee50",
  null
  
)

export default component.exports